import { Dialog, DialogTitle, DialogContent, Stack, TextField, DialogActions, Button, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import PostParams from '../../interfaces/PostParams';

export interface CreatePostDialogProps {
  open: boolean,
  onClose: (create: boolean, author: string, title: string, content: string) => void;
}

const CreatePostDialog = (props: CreatePostDialogProps) => {
  const { onClose, /*author, title, content,*/ open } = props;
  
  const authorPattern = '^[A-Za-z0-9_]+$';
  
  const [values, setValues] = useState<PostParams>({
    author: '',
    title: '',
    content: ''
  });
  
  const [dirty, setDirty] = useState({
    author: false,
    title: false,
    content: false
  });
  const hasError: {[name: string]: boolean} = {
    author: values.author.match(authorPattern) == null,
    title: values.title.length === 0,
    content: values.content.length === 0
  }
  const validForm = !hasError.author && !hasError.title && !hasError.content;
  
  const handleCancel = () => {
    onClose(false, '', '', '');
  };
  
  const handleSubmit = () => {
    onClose(true, values.author, values.title, values.content);
  };
  
  const handleChange = (name: string) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValues({ ...values, [name]: e.target.value.trim() });
    setDirty({ ...dirty, [name]: true });
  };
  
  return (
    <Dialog onClose={handleCancel} open={open} fullWidth>
      <DialogTitle>Create New Post</DialogTitle>
      <DialogContent>
        <form>
          <Stack spacing={2} marginBottom={2}>
            <TextField
              label="Author"
              variant="filled"
              inputProps={{ pattern: authorPattern }}
              required
              fullWidth
              error={hasError.author && dirty.author}
              helperText={(hasError.author && dirty.author) ? (values.author.length === 0 ? 'Who wrote this?' : 'Author must be alphanumeric!') : null}
              onChange={handleChange('author')}
            />
            <TextField
              label="Title"
              variant="filled"
              required
              fullWidth
              error={hasError.title && dirty.title}
              helperText={(hasError.title && dirty.title) ? 'Give your post a title!' : null}
              onChange={handleChange('title')}
            />
            <TextField
              label="Content"
              multiline
              variant="filled"
              required
              fullWidth
              error={hasError.content && dirty.content}
              helperText={(hasError.content && dirty.content) ? 'Give your post some content!' : null}
              onChange={handleChange('content')}
            />
          </Stack>
        </form>
        <Typography variant='body1'>
          <b>Be careful! Once you click Create, you won't be able to edit your post anymore! (Maybe I will implement actual user accounts and editing eventually.)</b><br /><br />
          NOTE: Creating posts may take time, so please wait after clicking Create.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!validForm}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreatePostDialog;
