import { Box, Card, CardContent, CardHeader, Container, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { config } from '../../config';
import Post from '../../interfaces/Post';
import { Link } from 'react-router-dom';
import TopBar from '../TopBar/TopBar';

const SinglePost = () => {
  const params = useParams();
  const id = params.id!;
  const [post, setPost] = useState<Post>();
  
  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(config.apiUrl + `/posts/${id}`);
      const postResp = await resp.json() as Post;
      setPost(postResp);
    };
    
    getPost();
  }, [id]);
  
  return (
    <div>
      <TopBar />
      <Container sx={{ marginY: 2 }} maxWidth='md'>
        <IconButton sx={{marginBottom: 2}} color="primary" component={Link} to="/" aria-label="back">
          <ArrowBack />
        </IconButton>
        <Card>
          <CardHeader
            title={<Typography variant='h5'>
              {post?.title}
            </Typography>}
            subheader={<Box>
                      <div>
                        {"by " + post?.author}
                      </div>
                      <div>
                        {new Date(post?.date!).toLocaleDateString(undefined,
                            { year: 'numeric', month: 'long', day: 'numeric' })}
                      </div>
                    </Box>}
          />
          <CardContent>
            <Typography variant="body1" sx={{ wordWrap: 'break-word' }} color="text.primary">
              {post?.content}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </div>
    
  )
};

export default SinglePost;
