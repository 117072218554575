import { Alert, Box, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Container, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { config } from '../../config';
import { Link } from 'react-router-dom';
import Post from '../../interfaces/Post';
import { Add } from '@mui/icons-material';
import PostParams from '../../interfaces/PostParams';
import CreatePostDialog from '../CreatePostDialog/CreatePostDialog';
import TopBar from '../TopBar/TopBar';

const Posts = () => {
  // List of all rendered posts.
  const [posts, setPosts] = useState<Post[]>([]);
  
  // Open the dialog.
  const [open, setOpen] = useState(false);
  
  // Notify post creation failures.
  const [notifyFailure, setNotifyFailure] = useState(false);
  
  // Are we creating a post right now.
  // (This is to indicate if we are working on your new post!)
  const [postCreationInProgress, setPostCreationInProgress] = useState(false);
  
  // Open the dialog
  const handleOpen = () => setOpen(true); 
  
  // When closing the dialog, send a POST request if we are not cancelling this dialog.
  // This creates a new post if successful.
  const handleClose = async (create: boolean, author: string, title: string, content: string) => {
    setOpen(false);
    const createPostParams: PostParams = {
      author: author,
      title: title,
      content: content
    };
    
    if (create) {
      setPostCreationInProgress(true);
      fetch(config.apiUrl + '/posts/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(createPostParams)
      }).then(async resp => {
        if (resp.ok) {
          const newPost = await resp.json() as Post;
          posts.splice(0, 0, newPost);
          setPosts(posts);
        } else {
          setNotifyFailure(true);
        }
      }).catch(() => {
        setNotifyFailure(true);
      }).finally(() => setPostCreationInProgress(false));
    }
  };
  
  // Handles notification closing.
  const handleNotificationClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    
    setNotifyFailure(false);
  };
  
  // Gets all the posts every minute.
  // (I will see if I can implement partial rendering in the future.)
  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(config.apiUrl + '/posts/');
      const postsResp = await resp.json() as Post[];
      postsResp.sort((a, b) => b.date - a.date);
      setPosts(postsResp);
    };
    
    const timer = setInterval(() => {
      getPosts();
    }, 60000);
    
    getPosts();
    
    return () => {
      clearInterval(timer);
    }
  }, []);
  
  return (
    <div>
      <TopBar />
      <Snackbar open={notifyFailure} autoHideDuration={6000} onClose={handleNotificationClose}>
        <Alert onClose={handleNotificationClose} severity='error' sx={{ width: '100%' }}>
          Something went wrong while creating your post. Oh no! :(
        </Alert>
      </Snackbar>
      <Container sx={{ width: 'md', marginY: 2 }}>
        <CircularProgress size={postCreationInProgress ? 20 : 0} />
        <IconButton
          disabled={postCreationInProgress}
          sx={{ marginBottom: 2, visibility: postCreationInProgress ? 'hidden' : 'visible' }}
          color="primary"
          onClick={handleOpen}
        >
          <Add />
        </IconButton>
        <CreatePostDialog open={open} onClose={handleClose} />
        <Grid container spacing={2}>
          {posts.map(post => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Card>
                <CardActionArea component={Link} to={`/${post.id}`}>
                  <CardHeader
                    sx={{ display: 'block', overflow: 'hidden' }}
                    title={<Typography noWrap variant='h5'>
                      {post.title}
                    </Typography>}
                    subheader={<Box>
                      <div>
                        {"by " + post.author}
                      </div>
                      <div>
                        {new Date(post.date).toLocaleDateString(undefined,
                            { year: 'numeric', month: 'long', day: 'numeric' })}
                      </div>
                    </Box>}
                  />
                  <CardContent>
                    <Typography noWrap variant="body2" color="text.primary">
                      {post.content}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Posts;
